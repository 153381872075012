<template>
  <StepModal
    v-model="localValue"
    :title="$t('sick_leave_management.title')"
    :current-step="currentStep"
    :steps="steps"
    :persistent="false"
    :show-steps="true"
    icon-name="mdi-account-outline"
  >
    <v-stepper v-model="currentStep" :steps="steps" :elevation="0" flat>
      <v-stepper-items>
        <v-stepper-content step="1">
          <SickLeaveTypeSelector v-model="sickLeaveSelectedType"/>
        </v-stepper-content>
        <v-stepper-content step="2">
          <SickLeaveTempForm v-if="showTempForm" v-model="sickLeaveData"/>
          <SickLeaveFinalForm v-else v-model="sickLeaveData" />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <template v-slot:actions>
      <div v-if="currentStep > 1" class="px-6">
        <DButton @click.native="reset">{{
            $t('common.return_back')
          }}</DButton>
        <DButton :primary="true" :loading="settingSickData" @click.native="save">{{
            $t('common.save')
          }}</DButton>
      </div>
    </template>
  </StepModal>
</template>

<script>
import StepModal from '@/components/StepModal/StepModal.vue';
import {default as SickLeaveTypeSelector, SICK_LEAVE_TYPES} from "@/SickLeaveManagement/components/SickLeaveTypeSelector.vue";
import CustomVmodelMixin from "@/mixins/custom-vmodel.mixin";
import SickLeaveTempForm from "@/SickLeaveManagement/components/SickLeaveTempForm.vue";
import SickLeaveFinalForm from "@/SickLeaveManagement/components/SickLeaveFinalForm.vue";
import DButton from "@/components/atoms/DButton.vue";
import {
  REASONS_TEMP,
  sendSickLeaveConfig,
  TYPES
} from "@/SickLeaveManagement/services/sick-leave-management";
import {mapActions} from "vuex";

export default {
  name: 'SickLeaveManagementModal',
  components: {
    DButton,
    SickLeaveTempForm,
    SickLeaveFinalForm,
    SickLeaveTypeSelector,
    StepModal,
  },
  mixins: [CustomVmodelMixin],
  props: {
    professionalId: {
      type: Number,
      required: true,
    },
    value: {
      type: Boolean,
      default: false,
    }
  },
  data: function () {
    return {
      currentStep: 1,
      steps: 2,
      sickLeaveSelectedType: null,
      sickLeaveData: null,
      settingSickData: false,
    };
  },
  computed: {
    showTempForm() {
      return this.sickLeaveSelectedType === SICK_LEAVE_TYPES.TEMP;
    }
  },
  watch: {
    sickLeaveSelectedType(val) {
      if (!val) {
        return;
      }

      this.nextStep();
    },
    localValue() {
      this.reset();
    }
  },

  methods: {
    ...mapActions('layout', ['showAlert']),
    reset() {
      this.currentStep = 1;
      this.sickLeaveSelectedType = null;
    },

    nextStep() {
      this.currentStep += 1;
    },

    async save() {
      try {
        this.settingSickData = true;

        await sendSickLeaveConfig(this.sickLeaveSelectedType === SICK_LEAVE_TYPES.TEMP ? TYPES.TEMP : TYPES.FINAL, this.professionalId, {
          ...this.sickLeaveData,
          reason: (this.sickLeaveData.reasonType === REASONS_TEMP[3].type) ? this.sickLeaveData.otherReasonDescription : this.sickLeaveData.reasonType,
        });

        this.localValue = false;
        this.showAlert({
          color: 'success',
          icon: 'mdi-check',
          message: this.$t('sick_leave_management.setting_ok'),
        });
        this.$emit('saved');
      } catch (e) {
        this.showAlert({ color: 'error', icon: 'mdi-alert-circle', message: this.$t('sick_leave_management.setting_ko') });
      } finally {
        this.settingSickData = false;
      }
    },
  },
};
</script>
