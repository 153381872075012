import { render, staticRenderFns } from "./ProfessionalDetailsAsAssistant.vue?vue&type=template&id=da3ac154&scoped=true"
import script from "./ProfessionalDetailsAsAssistant.vue?vue&type=script&lang=js"
export * from "./ProfessionalDetailsAsAssistant.vue?vue&type=script&lang=js"
import style0 from "./ProfessionalDetailsAsAssistant.vue?vue&type=style&index=0&id=da3ac154&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "da3ac154",
  null
  
)

export default component.exports