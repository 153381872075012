<template>
  <div class="sick-leave-final-form">
    <div class="reasons-selector mt-4">
      <div class="sick-leave-final-form__label">{{ $t('sick_leave_management.select_reason') }}</div>
      <v-select
        v-model="sickLeaveData.reasonType"
        :items="reasons.map(({type, name}) => ({
          type,
          name: $t(name),
        }))"
        outlined
        hide-selected
        item-value="type"
        item-text="name">
        <template slot="item" slot-scope="{ item }">
          <div class="py-1">
          <span class="reasons-selector__item">
            {{ item.name }}
          </span>
          </div>
        </template>
      </v-select>
    </div>
    <div v-if="showOtherDescription">
      <div class="sick-leave-final-form__label">{{ $t('sick_leave_management.other_reason') }}</div>
      <v-textarea
        v-model="sickLeaveData.otherReasonDescription"
        :placeholder="$t('sick_leave_management.reason')"
        :rows="3"
        outlined
        :error-messages="otherReasonDescriptionValidationError"
      />
    </div>
  </div>
</template>

<script>
import CustomVmodelMixin from "@/mixins/custom-vmodel.mixin";
import {useSickLeaveFinalForm} from "@/SickLeaveManagement/use/useSickLeaveFinalForm";

export default {
  name: 'SickLeaveTempForm',
  mixins: [CustomVmodelMixin],
  setup() {
    const {
      sickLeaveData,
      reasons,
      otherReasonDescriptionValidationError,
      submittingForm,
      submittedWithErrors,
      showOtherDescription,
      validateSickLeaveTempForm,
      submitSickLeaveForm,
      resetForm,
    } = useSickLeaveFinalForm();

    return {
      sickLeaveData,
      reasons,
      otherReasonDescriptionValidationError,
      submittedWithErrors,
      submittingForm,
      showOtherDescription,
      validateSickLeaveTempForm,
      submitSickLeaveForm,
      resetForm,
    };
  },
  computed: {
    isMobileVersion() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  created() {
    this.resetForm();
  },
  watch: {
    sickLeaveData: {
      handler(val) {
        this.$emit('input', val);
      },
      deep: true,
    }
  }
}

</script>

<style lang="scss">
$black: #212121;
$grey: #f1f1f1;
$highlightColor: #daf2f8;

.sick-leave-final-form {
  &__label {
    font-size: 16px;
    color: $black;
    margin-bottom: 0.25rem !important;
  }

  &__content {
    padding-top: 2.25rem;
  }

  &__divider {
    border: solid 1.2px var(--v-primary-base);
  }

  &__date {
    display: grid;
    column-gap: 1rem;
    grid-template-columns: repeat(1, 1fr);

    @media screen and (min-width: 480px) {
      & {
        grid-template-columns: repeat(1, 1fr);
      }
    }

    @media screen and (min-width: 768px) {
      & {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    @media screen and (min-width: 1024px) {
      & {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
}

.reasons-selector {
  &__no-data {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }

  &__item {
    font-size: rem(16);
    color: $black;
    font-weight: 500;
  }
}

.sick-leave-final-form {
  & .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
  > .v-input__control
  > .v-input__slot
  fieldset {
    border-color: $grey;
    border-width: 0.125rem;
  }
}
</style>
