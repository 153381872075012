import {ref} from 'vue';

import {REASONS_TEMP} from '../services/sick-leave-management';
import { computed } from 'vue';
import { formatDate, substractDaysFromDate } from '@/utils/dateer';
import useVuelidate from '@vuelidate/core';
import {helpers, required, requiredIf} from '@vuelidate/validators';

export function useSickLeaveTempForm() {
  const reasons = Array.from(REASONS_TEMP);

  const sickLeaveData = ref({
    reasonType: REASONS_TEMP[0].type,
    startDate: null,
    endDate: null,
    otherReasonDescription: null,
  })

  const submittedWithErrors = ref(false);
  const submittingForm = ref(false);
  const isNetworkError = ref(false);

  const showOtherDescription = computed(() => {
    return sickLeaveData.value.reasonType === REASONS_TEMP[3].type;
  });

  const validationRules = computed(() => ({
    startDate: {
      required: helpers.withMessage(
        'Falta seleccionar la fecha de inicio',
        required
      ),
      $autoDirty: true,
    },
    endDate: {
      required: helpers.withMessage(
        'Falta seleccionar la fecha de fin',
        required
      ),
      $autoDirty: true,
    },
    otherReasonDescription: {
      required: helpers.withMessage(
        'Especifique un motivo',
        requiredIf(function() {
          return showOtherDescription.value;
        })
      ),
      $autoDirty: true,
    },
  }));

  const v$ = useVuelidate(validationRules, { startDate: sickLeaveData.value.startDate, endDate: sickLeaveData.value.endDate, otherReasonDescription: sickLeaveData.value.otherReasonDescription }, { $scope: false });

  const validateSickLeaveTempForm = () => {
    submittedWithErrors.value = false;
    v$.value.$touch();
    submittedWithErrors.value = v$.value.$invalid;
  };

  const resetForm = () => {
    sickLeaveData.value = {
      reasonType: REASONS_TEMP[0].type,
      startDate: null,
      endDate: null,
      otherReasonDescription: null,
    };
  };

  const startDateFormatted = computed(() => {
    if (!sickLeaveData.value.startDate) {
      return null;
    }

    return formatDate(sickLeaveData.value.startDate);
  });

  const startDateValidationError = computed(() => {
    return submittedWithErrors.value ? v$.value.startDate.$errors.map(error => error.$message) : null;
  });

  const endDateFormatted = computed(() => {
    if (!sickLeaveData.value.endDate) {
      return null;
    }

    return formatDate(sickLeaveData.value.endDate);
  });

  const endDateValidationError = computed(() => {
    return submittedWithErrors.value ? v$.value.endDate.$errors.map(error => error.$message) : null;
  });

  const otherReasonDescriptionValidationError = computed(() => {
    return submittedWithErrors.value ? v$.value.otherReasonDescription.$errors.map(error => error.$message) : null;
  });

  const minEndDate = computed(() => {
    if (!sickLeaveData.value.startDate) {
      return null;
    }

    return substractDaysFromDate(new Date(sickLeaveData.value.startDate), 1).toISOString();
  });

  return {
    sickLeaveData,
    reasons,
    isNetworkError,
    startDateFormatted,
    endDateFormatted,
    minEndDate,
    v$,
    startDateValidationError,
    endDateValidationError,
    otherReasonDescriptionValidationError,
    showOtherDescription,
    submittingForm,
    submittedWithErrors,
    validateSickLeaveTempForm,
    resetForm,
  };
}
