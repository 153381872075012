<template>
  <div class="sick-leave-temp-form">
    <div class="sick-leave-temp-form__date mt-4">
      <div>
        <div class="sick-leave-temp-form__label">{{ $t('common.fecha_inicio') }}</div>
        <v-menu
          ref="startDateMenu"
          v-model="startDateMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
          data-test="sick-leave-input_start-date"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="startDateFormatted"
              readonly
              :placeholder="$t('common.date_placeholder')"
              outlined
              v-bind="attrs"
              :error-messages="startDateValidationError"
              v-on="on"
            />
          </template>
          <v-date-picker v-model="sickLeaveData.startDate" no-title data-test="sick-leave-date-picker_start-date" @input="startDateMenu = false" />
        </v-menu>
      </div>
      <div>
        <div class="sick-leave-temp-form__label">{{ $t('common.fecha_fin') }}</div>
        <v-menu
          ref="endDateMenu"
          v-model="endDateMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
          data-test="sick-leave-input_end-date"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="endDateFormatted"
              readonly
              :placeholder="$t('common.date_placeholder')"
              outlined
              v-bind="attrs"
              :error-messages="endDateValidationError"
              v-on="on"
            />
          </template>
          <v-date-picker v-model="sickLeaveData.endDate" data-test="sick-leave-date-picker_end-date" :min="minEndDate" no-title @input="endDateMenu = false"/>
        </v-menu>
      </div>
    </div>
    <v-divider class="sick-leave-temp-form__divider mt-2" />
    <div class="reasons-selector mt-8">
      <div class="sick-leave-temp-form__label">{{ $t('sick_leave_management.select_reason') }}</div>
      <v-select
        v-model="sickLeaveData.reasonType"
        :items="reasons.map(({type, name}) => ({
          type,
          name: $t(name),
        }))"
        outlined
        hide-selected
        item-value="type"
        item-text="name">
        <template slot="item" slot-scope="{ item }">
          <div class="py-1">
          <span class="reasons-selector__item">
            {{ item.name }}
          </span>
          </div>
        </template>
      </v-select>
    </div>
    <div v-if="showOtherDescription">
      <div class="sick-leave-temp-form__label">{{ $t('sick_leave_management.other_reason') }}</div>
      <v-textarea
        v-model="sickLeaveData.otherReasonDescription"
        :placeholder="$t('sick_leave_management.reason')"
        :rows="3"
        outlined
        :error-messages="otherReasonDescriptionValidationError"
      />
    </div>
  </div>
</template>

<script>
import CustomVmodelMixin from "@/mixins/custom-vmodel.mixin";
import {useSickLeaveTempForm} from "@/SickLeaveManagement/use/useSickLeaveTempForm";
import {parseDate} from "@/utils/dateer";

export default {
  name: 'SickLeaveTempForm',
  mixins: [CustomVmodelMixin],
  data: function () {
    return {
      startDateMenu: false,
      endDateMenu: false,
    };
  },

  computed: {
    isMobileVersion() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },

  watch: {
    sickLeaveData: {
      handler(val) {
        this.$emit('input', val);
      },
      deep: true,
    }
  },
  methods: {parseDate},
  setup() {
    const {
      sickLeaveData,
      reasons,
      startDateFormatted,
      endDateFormatted,
      minEndDate,
      startDateValidationError,
      endDateValidationError,
      otherReasonDescriptionValidationError,
      submittingForm,
      submittedWithErrors,
      showOtherDescription,
      validateSickLeaveTempForm,
      submitSickLeaveForm,
      resetForm,
    } = useSickLeaveTempForm();

    return {
      sickLeaveData,
      reasons,
      startDateFormatted,
      endDateFormatted,
      minEndDate,
      startDateValidationError,
      endDateValidationError,
      otherReasonDescriptionValidationError,
      submittedWithErrors,
      submittingForm,
      showOtherDescription,
      validateSickLeaveTempForm,
      submitSickLeaveForm,
      resetForm,
    };
  }
}

</script>

<style lang="scss">
$black: #212121;
$grey: #f1f1f1;
$highlightColor: #daf2f8;

.sick-leave-temp-form {
  &__label {
    font-size: 16px;
    color: $black;
    margin-bottom: 0.25rem !important;
  }

  &__content {
    padding-top: 2.25rem;
  }

  &__divider {
    border: solid 1.2px var(--v-primary-base);
  }

  &__date {
    display: grid;
    column-gap: 1rem;
    grid-template-columns: repeat(1, 1fr);

    @media screen and (min-width: 480px) {
      & {
        grid-template-columns: repeat(1, 1fr);
      }
    }

    @media screen and (min-width: 768px) {
      & {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    @media screen and (min-width: 1024px) {
      & {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
}

.reasons-selector {
  &__no-data {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }

  &__item {
    font-size: rem(16);
    color: $black;
    font-weight: 500;
  }
}

.sick-leave-temp-form {
  & .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
  > .v-input__control
  > .v-input__slot
  fieldset {
    border-color: $grey;
    border-width: 0.125rem;
  }
}
</style>
