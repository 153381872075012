<template>
  <v-card flat class="md-card-profile">
    <v-card-text>
      <div class="mt-4">
        <AccountStatus :professional-id="professionalId" />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import AccountStatus from "@/components/account/layout/AccountStatus.vue";

export default {
  name: "ProfessionalAccountStatus",
  components: {AccountStatus},
  props: {
    professionalId: {
      type: Number,
      required: true,
    }
  },
};
</script>
