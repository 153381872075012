<template>
  <RectangleSelector
    v-model="localValue"
    :items="sickLeaveTypes"
    name="sickLeaveType"
  />
</template>

<script>
import RectangleSelector from "@/components/molecules/RectangleSelector.vue";
import CustomVmodelMixin from "@/mixins/custom-vmodel.mixin";

export const SICK_LEAVE_TYPES = Object.freeze({
  TEMP: 'temp',
  FINAL: 'final'
});

export default {
  name: 'SickLeaveTypeSelector',
  components: {RectangleSelector},
  mixins: [CustomVmodelMixin],
  data: function () {
    return {
      sickLeaveTypes: Object.entries(SICK_LEAVE_TYPES).map(([_, type]) => (
        { name: this.$t(`sick_leave_management.${type}_sick_leave`), id: type }
      ))
    };
  }
}

</script>
