<template>
  <v-card flat class="md-card-profile mt-4">
    <v-card-text>
      <form class="mt-2">
        <v-layout wrap>
          <v-flex xs12 lg12 sm12>
            <v-card elevation="2" class="my-4 mx-3" style="min-height: 175px">
              <v-toolbar color="primary" dark flat dense cad>
                <v-toolbar-title class="subheading">{{ $t('agenda.configuracion') }}</v-toolbar-title>
              </v-toolbar>
              <v-divider />
              <v-card-text>
                <v-expansion-panels>
                  <v-expansion-panel v-for="(day, index) in weekdays" :key="index">
                    <v-card class="mb-0 container">
                      <v-card-title class="subtitle">
                        <v-icon color="black" class="mr-2">
                          {{ day.check ? 'mdi-checkbox-marked-circle-outline' : 'mdi-checkbox-blank-circle-outline' }}
                        </v-icon>
                        {{ day.name }}
                      </v-card-title>
                      <v-card-text>
                        <v-layout wrap>
                          <v-flex xs6 sm6 lg6>
                            <v-text-field
                              v-model="day.startTime"
                              type="time"
                              :label="$t('agenda.horainicio')"
                              :placeholder="$t('agenda.horainicio')"
                              append-icon="mdi-clock-outline"
                              @change="validateHour(index, 'startTime')"
                            />
                          </v-flex>
                          <v-flex xs6 sm6 lg6>
                            <v-text-field
                              v-model="day.endTime"
                              type="time"
                              :label="$t('agenda.horafin')"
                              :placeholder="$t('agenda.horafin')"
                              append-icon="mdi-clock-outline"
                              @change="validateHour(index, 'endTime')"
                            />
                          </v-flex>
                          <v-flex v-if="checkDay[index].segundo_rango" xs1>
                            <v-btn icon @click="disableSecondRange(index)">
                              <v-icon color="primary">mdi-delete</v-icon>
                            </v-btn>
                          </v-flex>
                          <v-flex v-if="checkDay[index].segundo_rango" xs5 sm5 lg5>
                            <v-text-field
                              v-model="day.startTime2"
                              type="time"
                              :label="$t('agenda.horainicio') + ' 2'"
                              :placeholder="$t('agenda.horainicio')"
                              append-icon="mdi-clock-outline"
                              @change="validateHour(index, 'startTime2')"
                            />
                          </v-flex>
                          <v-flex v-if="checkDay[index].segundo_rango" xs6 sm6 lg6>
                            <v-text-field
                              v-model="day.endTime2"
                              type="time"
                              :label="$t('agenda.horafin') + ' 2'"
                              :placeholder="$t('agenda.horafin')"
                              append-icon="mdi-clock-outline"
                              @change="validateHour(index, 'endTime2')"
                            />
                          </v-flex>
                          <v-flex
                            v-if="checkDay[index].habilitado && day.startTime !== '' && day.endTime !== ''"
                            class="text-center"
                            xs12
                          >
                            <v-btn color="primary" rounded @click="enableSecondRange(index)">
                              <v-icon>mdi-plus</v-icon> {{ $t('agenda.nuevo_rango') }}
                            </v-btn>
                          </v-flex>
                        </v-layout>
                      </v-card-text>
                    </v-card>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </form>
    </v-card-text>
    <v-card-text>
      <dias-no-disponibles :usuario_id="professionalId" :schedule-id="scheduleId" />
    </v-card-text>
  </v-card>
</template>

<script>
import DiasNoDisponibles from '@/views/ajustes/DiasNoDisponibles';

export default {
  components: { DiasNoDisponibles },
  props: {
    weekdays: {
      type: Array,
      default: () => [],
    },
    checkDay: {
      type: Array,
      default: () => [],
    },
    professionalId: {
      type: Number,
      default: 0,
    },
    scheduleId: {
      type: Number,
      default: null,
    },
  },

  methods: {
    editData() {
      this.$emit('editingData', true);
    },

    validateHour(index, name) {
      const value = this.weekdays[index][name];
      if (value !== '') {
        const step = 5;
        const fullTime = value.split(':');
        const exceedsMaxTime = Math.ceil(fullTime[1] / step) * step >= 60;
        const minuteTime = exceedsMaxTime ? 0 : Math.ceil(fullTime[1] / step) * step;
        const minute = minuteTime < 10 ? '0' + minuteTime : minuteTime;
        const hourTime = exceedsMaxTime && fullTime[0] < '23' ? parseInt(fullTime[0]) + 1 : fullTime[0];
        const hour = hourTime < 10 ? '0' + parseInt(hourTime) : hourTime;
        this.weekdays[index][name] = hour + ':' + minute;
        this.validate();
      } else {
        this.weekdays[index].check = false;
      }
    },

    validate() {
      let isValid = true;
      this.weekdays.forEach(function(day, index) {
        if (day.startTime !== '' && day.endTime !== '') {
          const isValidStart = this.validateFirstRange(index, 'start');
          const isValidEnd = this.validateFirstRange(index, 'end');
          if (day.startTime2 !== '' && day.endTime2 !== '') {
            const isValidStart2 = this.validateSecondRange(index, 'start');
            const isValidEnd2 = this.validateSecondRange(index, 'end');
            day.check = isValidStart && isValidEnd && isValidStart2 && isValidEnd2;
          } else {
            day.check = isValidStart && isValidEnd;
          }
          if (!day.check) {
            isValid = false;
          }
        } else {
          day.check = false;
        }
      }, this);
      this.$emit('scheduleValid', isValid);
      this.editData();
    },

    enableSecondRange(index) {
      if (!this.checkDay[index].segundo_rango) {
        this.checkDay[index].segundo_rango = true;
        this.weekdays[index].startTime2 = this.weekdays[index].endTime;
      } else if (!this.weekdays[index].tercer_rango) {
        this.checkDay[index].tercer_rango = true;
        this.weekdays[index].startTime3 = this.weekdays[index].endTime2;
      }
      this.checkDay[index].habilitado = false;
    },

    disableSecondRange(index) {
      this.checkDay[index].segundo_rango = false;
      this.checkDay[index].habilitado = true;
      this.weekdays[index].startTime2 = '';
      this.weekdays[index].endTime2 = '';
      this.editData();
    },

    validateFirstRange(i, type) {
      let isValid = true;
      if (
        this.checkInvalidHour(this.weekdays[i].startTime) ||
        this.checkInvalidHour(this.weekdays[i].endTime) ||
        this.checkInvalidHour(this.weekdays[i].startTime2) ||
        this.checkInvalidHour(this.weekdays[i].endTime2)
      ) {
        this.$toast.error(this.$t('errors.incorrect_hour'));
        isValid = false;
      } else {
        if (
          type === 'start' &&
          this.weekdays[i].startTime > this.weekdays[i].endTime &&
          this.weekdays[i].endTime !== ''
        ) {
          this.$toast.error(this.$t('agenda.hora_inicio_debe_ser'));
          isValid = false;
        } else if (
          type === 'end' &&
          ((this.weekdays[i].startTime > this.weekdays[i].endTime && this.weekdays[i].startTime !== '') ||
            (this.weekdays[i].startTime2 < this.weekdays[i].endTime && this.weekdays[i].startTime2 !== ''))
        ) {
          this.$toast.error(this.$t('agenda.hora_inicio_debe_ser'));
          isValid = false;
        }
      }
      return isValid;
    },

    validateSecondRange(i, type) {
      let isValid = true;
      if (type === 'start') {
        if (this.weekdays[i].startTime2 > this.weekdays[i].endTime2 && this.weekdays[i].endTime2 !== '') {
          this.$toast.error(this.$t('agenda.hora_inicio_debe_ser'));
          isValid = false;
        } else if (this.weekdays[i].startTime2 < this.weekdays[i].endTime && this.weekdays[i].endTime !== '') {
          this.$toast.error(this.$t('agenda.hora_inicio_debe_ser'));
          isValid = false;
        }
      } else if (type === 'end') {
        if (this.weekdays[i].startTime2 > this.weekdays[i].endTime2 && this.weekdays[i].startTime2 !== '') {
          this.$toast.error(this.$t('agenda.hora_inicio_debe_ser'));
          isValid = false;
        } else if (this.weekdays[i].endTime2 < this.weekdays[i].endTime && this.weekdays[i].endTime !== '') {
          this.$toast.error(this.$t('agenda.hora_inicio_debe_ser'));
          isValid = false;
        }
      }
      return isValid;
    },

    checkInvalidHour(hour) {
      const hourRegex = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
      const correctHour = hourRegex.exec(hour);
      return hour !== '' && !correctHour;
    },
  },
};
</script>
<style scoped lang="css">
.md-card-profile {
  width: 96%;
  margin: -25px auto 0;
  overflow: hidden;
  -webkit-box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0 rgba(0, 0, 0, 0.12),
    0 8px 10px -5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0 rgba(0, 0, 0, 0.12),
    0 8px 10px -5px rgba(0, 0, 0, 0.2);
}
</style>
