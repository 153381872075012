<template>
  <div class="account-status">
    <v-card-title class="text-h6 font-weight-regular lighten-2 mb-1 px-0 text-dark">
      {{ $t('account.account_status') }}
    </v-card-title>
    <!-- password -->
    <article class="account-status__group">
      <v-card-text class="px-0 py-0 text-subtitle-1 mb-2 flex flex-row align-items-center sick-leave__title">
        <span class="text-dark">{{ $t('account.sick_leave_title') }}</span>
        <template v-if="!loadingAccountStatus && !isAvailable">
          <v-chip small label :color="$vuetify.theme.currentTheme.error" text-color="white">
            {{ sickTypeName }}: {{ sickReason }}
          </v-chip>
          <span class="sick-leave__dates">{{sickStartAtFormatted}} - {{ sickEndAtFormatted }}</span>
        </template>
      </v-card-text>
      <div class="account-status__flex">
        <v-card-text class="px-0 py-0 text-body-2 text--secondary font-weight-regular account-status__hint">
          {{ $t('account.sick_leave_description') }}
        </v-card-text>
        <v-btn v-if="!loadingAccountStatus && isAvailable" color="primary" outlined class="account-status__button" @click="openSickLeaveManagement">
          {{ $t('account.sick_leave_cta') }}
        </v-btn>
      </div>
    </article>
    <SickLeaveManagementModal v-model="showSickLeaveManagementModal" :professional-id="professionalId" @saved="retrieve"/>
  </div>
</template>

<script>

import SickLeaveManagementModal from "@/SickLeaveManagement/components/SickLeaveManagementModal.vue";
import {
  REASONS_FINAL,
  REASONS_TEMP,
  retrieveSickLeaveConfig
} from "@/SickLeaveManagement/services/sick-leave-management";
import dayjs from "dayjs";

export default {
  name: 'AccountStatus',
  components: {SickLeaveManagementModal},
  props: {
    professionalId: {
      type: Number,
      required: true,
    }
  },
  data: function () {
    return {
      showSickLeaveManagementModal: false,
      accountStatus: null,
      loadingAccountStatus: true,
    };
  },
  computed: {
    isAvailable() {
      if (!this.accountStatus) {
        return false;
      }

      return this.accountStatus.type === 1;
    },
    sickTypeName() {
      return this.accountStatus && this.accountStatus.type === 0 ? this.$t('sick_leave_management.final_sick_leave') : this.$t('sick_leave_management.temp_sick_leave');
    },
    sickReason() {
      if (!this.accountStatus) {
        return "";
      }

      if (this.accountStatus.type === 0 && REASONS_FINAL.some((reason) => (reason.type === this.accountStatus.reason))) {
        return this.$t(`sick_leave_management.${this.accountStatus.reason}`);
      } else if (this.accountStatus.type === 2 && REASONS_TEMP.some((reason) => (reason.type === this.accountStatus.reason))) {
        return this.$t(`sick_leave_management.${this.accountStatus.reason}`);
      } else if(this.accountStatus.type === 0 || this.accountStatus.type === 1) {
        return this.$t('sick_leave_management.other')
      }
    },
    sickStartAtFormatted() {
      return this.accountStatus && this.accountStatus.start_at && dayjs(this.accountStatus.start_at).format('DD/MM/YYYY');
    },
    sickEndAtFormatted() {
      return this.accountStatus && this.accountStatus.end_at && dayjs(this.accountStatus.end_at).format('DD/MM/YYYY');
    },
  },
  created() {
    this.retrieve();
  },
  methods: {
    retrieve() {
      retrieveSickLeaveConfig(this.professionalId)
        .then((data) => {
          this.accountStatus = data;
        })
        .catch((err) => {

        })
        .finally(() => {
          this.loadingAccountStatus = false;
        });
    },
    openSickLeaveManagement() {
      this.showSickLeaveManagementModal = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.text-dark {
  color: rgba(0, 0, 0, 0.87);
}

.account-status {
  &__flex {
    display: block;

    @include breakpoint(md) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__button {
    margin-top: rem(16);
    margin-bottom: rem(16);

    @include breakpoint(md) {
      margin-top: 0;
    }
  }

  &__hint {
    width: auto;

    @include breakpoint(md) {
      width: 50%;
    }
  }

  &__group-title {
    display: block;

    @include breakpoint(md) {
      display: inline;
    }
  }
}

.sick-leave {
  &__title {
    @include breakpoint(md) {
      gap: 1rem;
    }
    gap: 0.5rem;
    align-items: center;
    flex-wrap: wrap;
  }

  &__dates {
    color: #AAAAAA;
    font-size: 12px;
  }
}
</style>
