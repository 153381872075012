import { ref } from 'vue';

import {REASONS_FINAL} from '../services/sick-leave-management';
import { computed } from 'vue';
import useVuelidate from '@vuelidate/core';
import {helpers, requiredIf} from '@vuelidate/validators';

export function useSickLeaveFinalForm() {
  const reasons = Array.from(REASONS_FINAL);
  const sickLeaveData = ref({
    reasonType: REASONS_FINAL[0].type,
    otherReasonDescription: null,
  })

  const submittedWithErrors = ref(false);
  const submittingForm = ref(false);
  const isNetworkError = ref(false);

  const showOtherDescription = computed(() => {
    return sickLeaveData.value.reasonType === REASONS_FINAL[4].type;
  });

  const validationRules = computed(() => ({
    otherReasonDescription: {
      required: helpers.withMessage(
        'Especifique un motivo',
        requiredIf(function() {
          return showOtherDescription.value;
        })
      ),
      $autoDirty: true,
    },
  }));

  const v$ = useVuelidate(validationRules, { otherReasonDescription: sickLeaveData.value.otherReasonDescription }, { $scope: false });

  const validateSickLeaveTempForm = () => {
    submittedWithErrors.value = false;
    v$.value.$touch();
    submittedWithErrors.value = v$.value.$invalid;
  };

  const resetForm = () => {
    sickLeaveData.value = {
      reasonType: REASONS_FINAL[0].type,
      otherReasonDescription: null,
    };
  };

  const otherReasonDescriptionValidationError = computed(() => {
    return submittedWithErrors.value ? v$.value.otherReasonDescription.$errors.map(error => error.$message) : null;
  });

  return {
    sickLeaveData,
    reasons,
    isNetworkError,
    v$,
    otherReasonDescriptionValidationError,
    showOtherDescription,
    submittingForm,
    submittedWithErrors,
    validateSickLeaveTempForm,
    resetForm
  };
}
